/* eslint jsx-a11y/label-has-for:0 */

import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout, Wrapper, Header } from '../components'

import config from '../../config'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  form {
    p {
      label,
      input {
        display: block;
      }
      input {
        min-width: 275px;
        margin-top: 0.5rem;
      }
      textarea {
        resize: vertical;
        min-height: 150px;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`

const Now = () => (
  <Layout>
    <Wrapper>
      <Helmet title={`Now | ${config.siteTitle}`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
      </Header>
      <Content>
        <h2>What Sheree Is Doing Now</h2>
        <h3>Work</h3>
        <ul>
          <li>Consulting on test and quality for small clients.</li>
          <li>Looking for their next full time role.</li>
          <li>Living the remote life from Portland, Oregon.</li>
        </ul>
        <h3>Personal</h3>
        <ul>
          <li>Becoming one of those plant people.</li>
          <li>Going for a lot of walks.</li>
          <li>Selling on Depop to afford shopping on Depop.</li>
          <li>Ignoring Twitter.</li>
          <li>Getting into skincare.</li>
          <li>Buying more art than they can reasonably hang.</li>
        </ul>
      </Content>
    </Wrapper>
  </Layout>
)

export default Now
